.sidebar{
    display: flex;
    flex-direction: column;
    flex: 0.35;
}
.sidebar_search{
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    height: 39px;
    padding: 10px;
}
.sidebar_searchContainer{
    display: flex;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 35px;
    border-radius: 20px;
}
.sidebar_searchContainer > .MuiSvgIcon-root{
    color: gray;
    padding: 10px;
}
.sidebar_searchContainer > input{
    flex: 0.9;
    border: none;
    margin-left: 10px;
}



.sidebar_header{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-right: 1px solid lightgray ;
}

.sidebar_headerRight{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 10vw;
}
.sidebar_headerRight > .MuiSvgIcon-root{
    margin-right: 2vw;
    font-size: 24px !important;
}
.sidebar_chats{
    flex: 1;
    background-color: white;
    overflow: scroll;
}
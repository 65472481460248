.sidebarChat{
    display: flex;
    padding: 20px;
    cursor: pointer;
    border-bottom: 1px solid #f6f6f6;
}
a{
    text-decoration: none !important;
    color: black;

}
.sidebarChat:hover{
    background-color: #ebebeb;
}

.sidebarChat_info > h2{
    font-size: 16px;
    margin-bottom: 8px;
}

.sidebarChat_info {
    margin-left: 15px;
}

